import { useState } from "react";
import { toast } from "react-hot-toast";
import api from "../api/api";
const Enquiry = () => {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [countryName, setCountryName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [product, setProduct] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState("Submit");
  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    const data = {
      name: name,
      company: companyName,
      country: countryName,
      phone: phone,
      email: email,
      product: product,
      message: message,
    };

    if (name && companyName && countryName && phone && product && email && message) {
      try {
        setIsSuccess("Sending...");
        api
          .post(
            "https://hr78luoqy2.execute-api.ap-southeast-2.amazonaws.com/enquiry",
            data
          )
          .then((res) => {
            if (res.status === 200) {
              toast.success("Message sent successfully!");
              setIsSuccess("Submit");
              setName("");
              setPhone("");
              setEmail("");
              setProduct("");
              setMessage("");
              setIsSubmit(false);
            }
          });
      } catch (err) {
        console.log(err);
        setIsSubmit(false);
        setIsSuccess("Submit");
      }
    }
  };

  return (
    <div className="enquiry">
      <div className="container py-4 d-flex justify-content-center">
        <div className="enquiry-container ">
          <div>
            <h3>Enquiry Form</h3>
            <p>
              We're here to assist you with any information you need. Please
              provide the necessary details so that we can better address your
              enquiry.
            </p>
          </div>

          <form>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-2">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter Name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                  {isSubmit && !name && (
                    <small className="text-secondary">Enter name</small>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <label>Company Name</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter Company Name"
                    onChange={(e) => setCompanyName(e.target.value)}
                    value={companyName}
                  />
                  {isSubmit && !companyName && (
                    <small className="text-secondary">Enter company name</small>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <label>Country</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter Country Name"
                    onChange={(e) => setCountryName(e.target.value)}
                    value={countryName}
                  />
                  {isSubmit && !countryName && (
                    <small className="text-secondary">Enter country name</small>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <label>Phone</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter Phone Number"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                  {isSubmit && !phone && (
                    <small className="text-secondary">Enter phone number</small>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-2">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  {isSubmit && !email && (
                    <small className="text-secondary">Enter email</small>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <label>Select Product</label>
                  <select
                    className="form-input"
                    onChange={(e) => setProduct(e.target.value)}
                    value={product}
                  >
                    <option value={""}>--Select Product-</option>
                    <option>Lima</option>
                    <option>Nova</option>
                    <option>Byon</option>
                    <option>Sierra</option>
                    <option>Others</option>
                  </select>
                  {isSubmit && !product && (
                    <small className="text-secondary">Select Product</small>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-2">
                  <label>Message</label>
                  <textarea
                    className="form-input"
                    placeholder="Enter Message"
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                  ></textarea>
                  {isSubmit && !message && (
                    <small className="text-secondary">Enter message</small>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex">
              <button
                className="btn-brand-primary w-100"
                onClick={handleSubmit}
              >
                {isSuccess}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
