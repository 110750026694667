import { useEffect } from "react";
import { FaLayerGroup } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoColorPalette, IoLeafOutline } from "react-icons/io5";
import { GiFactory } from "react-icons/gi";
import { AiFillCheckCircle } from "react-icons/ai";
import { MdBusiness } from "react-icons/md";
import Testimonial from "../components/Testimonial";
import { Link } from "react-router-dom";
import maskcare from "./../assets/images/mufid-majnun-oI20ehIGNd4-unsplash.jpg";
import medicare from "./../assets/images/3dvisu-A4MkgsyXt1Q-unsplash-removebg-preview.png";
import nova from "./../assets/images/Medzon-Nova.png";
import byon from "./../assets/images/Medzon-Byon.png";
import lima from "./../assets/images/Medzon-Lima.png";
import seirra from "./../assets/images/Medzon-Seirra-Mockup-3.png";

const Home = () => {
  const CustomPrevArrow = (props) => {
    return <div />;
  };

  const CustomNextArrow = (props) => {
    return <div />;
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };
  return (
    <>
      <div className="hero">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-6">
              <p className="m-0">
                We have the perfect glove for your unique needs
              </p>
              <h3 className="my-3">Step into Unrivaled Protection</h3>
              <p className="mb-4">
                Join hands with Medzon and embrace a new standard of hand
                protection. Our gloves don't just shield your hands – they
                empower you to perform, create, and explore with confidence.
              </p>
              <div className="d-flex gap-2 ">
                <Link to={"products"} className="btn-white">
                  Explore Our Products
                </Link>
                <Link to={"contact"} className="btn-brand-primary-outline">
                  Contact Us
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <Slider {...settings}>
                <div className="hero-right ">
                  <img src={lima} width={"100%"} />
                </div>
                <div className="hero-right ">
                  <img src={nova} width={"100%"} />
                </div>
                <div className="hero-right ">
                  <img src={byon} width={"100%"} />
                </div>
                <div className="hero-right ">
                  <img src={seirra} width={"100%"} />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="features bg-light">
        <div className="container">
          <div className="text-center">
            <h3>Discover the Future of Gloves Manufacturing and Sales</h3>
            <p>
              {" "}
              Redefining Excellence in Handwear: Unveiling Unmatched Quality,
              Customization, and Innovation to Safeguard Your Hands in Every
              Industry and Style
            </p>
          </div>

          <div className="row">
            <div className="col-md-6 col-lg-4 d-flex">
              <div className="features-list">
                <FaLayerGroup />

                <h4>Extensive Glove Collections</h4>
                <p>
                  Discover a wide range of glove collections meticulously
                  designed to cater to various industries, purposes, and styles.
                  Whether you need heavy-duty industrial gloves, medical-grade
                  protection, or fashion-forward accessories, our diverse
                  selection ensures you find the perfect fit.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 d-flex">
              <div className="features-list">
                <IoColorPalette />
                <h4>Customization Options</h4>
                <p>
                  Tailor your gloves to match your unique requirements and
                  branding. Our customizable solutions allow you to choose
                  materials, colors, logos, and even design elements, ensuring
                  that your gloves are a true reflection of your vision and
                  identity.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 d-flex">
              <div className="features-list">
                <GiFactory />
                <h4>Innovative Manufacturing Techniques</h4>
                <p>
                  Experience gloves that embody the perfect blend of traditional
                  craftsmanship and modern technology. Our gloves are
                  manufactured using cutting-edge techniques that prioritize
                  comfort, durability, and performance, setting new standards in
                  hand protection.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 d-flex">
              <div className="features-list">
                <AiFillCheckCircle />
                <h4>Quality Assurance</h4>
                <p>
                  Rest assured that every pair of gloves undergoes rigorous
                  quality control checks. Our commitment to excellence ensures
                  that you receive gloves of the highest quality, meeting
                  industry standards and exceeding your expectations.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 d-flex">
              <div className="features-list">
                <MdBusiness />
                <h4>Industry Expertise</h4>
                <p>
                  Benefit from our extensive knowledge and experience in gloves
                  manufacturing. We cater to a wide range of industries,
                  understanding the unique demands of each, and crafting gloves
                  that address specific challenges while enhancing safety and
                  efficiency.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 d-flex">
              <div className="features-list">
                <IoLeafOutline />
                <h4>Sustainability Initiatives</h4>
                <p>
                  We're dedicated to environmental responsibility. Learn about
                  our sustainable manufacturing practices, from sourcing
                  eco-friendly materials to minimizing waste, as we strive to
                  create gloves that not only protect hands but also contribute
                  to a greener future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="why-choose-us py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div>
                <h3>Choose Us for Unrivaled Glove Solutions</h3>
                <p>
                  Discover Why We're the Preferred Choice for Quality Gloves
                  Manufacturing and Sales
                </p>
                <ul className="list-unstyled">
                  <li className="d-flex gap-2">
                    <span>
                      <AiFillCheckCircle />
                    </span>
                    <p>
                      Every pair of gloves we produce undergoes rigorous quality
                      control to ensure excellence in design, durability, and
                      performance.
                    </p>
                  </li>
                  <li className="d-flex gap-2">
                    <span>
                      <AiFillCheckCircle />
                    </span>
                    <p>
                      We continuously push boundaries by embracing technological
                      advancements, resulting in gloves that embody innovation
                      and creativity.
                    </p>
                  </li>
                  <li className="d-flex gap-2">
                    <span>
                      <AiFillCheckCircle />
                    </span>
                    <p>
                      Tailored solutions are our specialty. Collaborate with us
                      to create gloves that perfectly match your requirements,
                      branding, and unique needs.
                    </p>
                  </li>
                  <li className="d-flex gap-2">
                    <span>
                      <AiFillCheckCircle />
                    </span>
                    <p>
                      {" "}
                      We are committed to minimizing our environmental
                      footprint. Our sustainable manufacturing practices reflect
                      our dedication to a greener future.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <img src={maskcare} className="choose-us-img" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <Testimonial />
      </div>
    </>
  );
};

export default Home;
