import { useEffect } from "react";
import { Link } from "react-router-dom";
import lima from "./../assets/images/lima-product.png";
import nova from "./../assets/images/nova-product.png";
import byon from "./../assets/images/byon-product.png";
import sierra from "./../assets/images/sierra-product.png";
import Header from "../components/Header";
import limaLogo from "./../assets/images/lima-logo.png";
import novaLogo from "./../assets/images/nova-logo.png";
import byonLogo from "./../assets/images/byon-logo.png";
import sierraLogo from "./../assets/images/sierra-logo.png";

const OurProducts = () => {
  return (
    <div className="">
      <div className="heading-txt py-3">
        <div className="container">
          <h2>Our Products</h2>
        </div>
      </div>
      <div className="container">
        <div className="row  pb-5">
          <div className="col-md-6 col-lg-3 col-sm-6 d-flex justify-content-center">
            <div className="product">
              {/* <h3>Medzon - Lima</h3> */}
              <img src={limaLogo} className="title-img" />

              <img src={lima} />
              {/* <p className="my-2">
              Medzon Lima, our non-sterile powdered and powder free gloves are
              made with high quality manufacturing & natural rubber latex to
              deliver comfort & barrier protection
            </p> */}
              <p>Latex Examination Gloves Powdered | Powder free</p>
              <Link
                to={"/product/lima"}
                className="btn-brand-primary mt-auto w-100"
              >
                See More Details
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 col-sm-6 d-flex justify-content-center">
            <div className="product">
              {/* <h3>Medzon - Nova</h3> */}
              <img src={novaLogo} className="title-img" />

              <img src={nova} />
              {/* <p className="my-2">
              Medzon Nova, our non-sterile glove is made with high quality
              manufacturing to provide excellent tactile sensitivity & dexterity
              with exceptional comfort, fit and feel
            </p> */}
              <p>Nitrile Examination Gloves Powder free</p>

              <Link
                to={"/product/nova"}
                className="btn-brand-primary mt-auto w-100"
              >
                See More Details
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 col-sm-6 d-flex justify-content-center">
            <div className="product">
              {/* <h3>Medzon - Byon</h3> */}
              <img src={byonLogo} className="title-img" />

              <img src={byon} />
              {/* <p className="my-2">
              Medzon Byon, our bio-degradable powder free gloves are made with
              high quality manufacturing & natural rubber latex to deliver
              comfort & barrier protection.
            </p> */}
              <p>Nitrile Examination Gloves Bio-degradeable - Powder free</p>
              <Link
                to={"/product/byon"}
                className="btn-brand-primary mt-auto w-100"
              >
                See More Details
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 col-sm-6 d-flex justify-content-center">
            <div className="product">
              {/* <h3>Medzon - Sierra</h3> */}
              <img src={sierraLogo} className="title-img" />

              <img src={sierra} />
              {/* <p className="my-2">
              Medzon Sierra, our sterile surgical powder free gloves is designed
              to deliver comfort & barrier protection with a thin film
              formulation for unrestricted tactile sensitivity. Polymer coated
              powder free interior enables incredible smooth donning experience
            </p> */}
              <p>Latex Sterile Surgical Gloves Powdered | Powder free</p>
              <Link
                to={"/product/sierra"}
                className="btn-brand-primary mt-auto w-100"
              >
                See More Details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurProducts;
