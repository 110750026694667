import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./views/Home";
import MainLayout from "./components/MainLayout";
import OurProducts from "./views/OurProducts";
import AboutUs from "./views/AboutUs";
import ContactUs from "./views/ContactUs";
import Lima from "./components/Lima";
import Nova from "./components/Nova";
import ScrollToTop from "./components/ScrollToTop";
import Byon from "./components/Byon";
import Sierra from "./components/Sierra";
import Enquiry from "./components/Enquiry";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="products" element={<OurProducts />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="contact" element={<ContactUs />} />
          <Route path="product/lima" element={<Lima />} />
          <Route path="product/nova" element={<Nova />} />
          <Route path="product/byon" element={<Byon />} />
          <Route path="product/sierra" element={<Sierra />} />
          <Route path="enquire" element={<Enquiry />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
