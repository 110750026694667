import nova from "./../assets/images/nova-product.png";
import novaLogo from "./../assets/images/nova-logo.png";
const Nova = () => {
  return (
    <div className="nova py-3">
      <div className="container">
        <div className="row align-items-center pb-4">
          <div className="col-md-6 d-flex justify-content-center">
            <img src={nova} className="nova-img" />
          </div>
          <div className="col-md-6">
            <div>
              <div className="mb-3 pb-2 border-bottom">
                <img src={novaLogo} width={200} />
              </div>
              <h3>Nitrile Examination Gloves Powder free</h3>
              <p>
                Medzon Nova, our non-sterile glove is made with high quality
                manufacturing to provide excellent tactile sensitivity &
                dexterity with exceptional comfort, fit and feel
              </p>
              <div>
                <h4>Key Features and Benefits</h4>
                <p>
                  Exclusive glove formulation that provides strong and reliable
                  gloves. Made with unique anatomic formers to enhance comfort
                  and fit and to reduce hand fatigue. Powder free gloves to
                  minimise adverse effects.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="my-4">
          <h4>Product Description</h4>
          <table className="table table-sm table-bordered">
            <tbody>
              <tr>
                <td className="bg-light">Type</td>
                <td>Nitrile Examination Glove, Powder free, Non-sterile</td>
              </tr>
              <tr>
                <td className="bg-light">Material</td>
                <td>Natural High Grade Rubber Latex</td>
              </tr>
              <tr>
                <td className="bg-light">Design</td>
                <td>Ambidextrous, beaded cuff, Finger Textured, Chlorinated</td>
              </tr>
              <tr>
                <td className="bg-light">Colours</td>
                <td>Light Blue, Dark Blue, Violet Blue, White & Black</td>
              </tr>
              <tr>
                <td className="bg-light">Shelf Life</td>
                <td>5 years from the date of manufacturing</td>
              </tr>
              <tr>
                <td className="bg-light">Packing Format</td>
                <td>100 pcs gloves x 10 dispensers x 1 carton</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row mb-4">
          <div className="col-md-6">
            <div>
              <h4>Performance Specifications</h4>
              <table className="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th className="bg-light">Description</th>
                    <th className="bg-light">Size</th>
                    <th className="bg-light">Standard</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Length(mm)</td>
                    <td>All Sizes</td>
                    <td>min 240</td>
                  </tr>
                  <tr>
                    <td>Palm Width(mm)</td>
                    <td>
                      <ul className="list-unstyled d-flex flex-column">
                        <li>XS</li>
                        <li>S</li>
                        <li>M</li>
                        <li>L</li>
                        <li>XL</li>
                      </ul>
                    </td>
                    <td>
                      {" "}
                      <ul className="list-unstyled d-flex flex-column">
                        <li>75 +/-4</li>
                        <li>86 +/-4</li>
                        <li>96 +/-4</li>
                        <li>107 +/-4</li>
                        <li>115 +/-4</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Thickness (mm) *single wall</td>
                    <td>All Sizes</td>
                    <td>
                      {" "}
                      <ul className="list-unstyled d-flex flex-column">
                        <li>Finger: 13 +/- 3 mm (from tip)</li>
                        <li>Palm: min 0.05 - 0.07 (centre of palm)</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h4>Physical Properties</h4>
              <table className="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th className="bg-light">Description</th>
                    <th colSpan={2} className="bg-light">
                      Standard
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>Before Aging</th>
                    <th>After Aging</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Elongation at Break (%)</td>
                    <td>Min 500%</td>
                    <td>Min 400%</td>
                  </tr>
                  <tr>
                    <td>Tensile Strength (MPa)</td>
                    <td>Min 14</td>
                    <td>Min 14</td>
                  </tr>
                  <tr>
                    <td>Force at break (N)</td>
                    <td>Min 6N</td>
                    <td>Min 6N</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nova;
