import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { BiMenuAltRight } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";
import logo from "./../assets/images/medzon-logo.png";
import mafaLogo from "./../assets/images/mafalogo.png";
const Header = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  return (
    <div className="header">
      {/* <div class="top-bar">
        <div class="container py-2">
          <ul class="d-flex gap-4 list-unstyled m-0">
            <li>
              <a href="mailto:info@mahfaresources.com">
                Email: info@mahfaresources.com
              </a>
            </li>
          </ul>
        </div>
      </div> */}
      <div className="container">
        <div className="d-flex justify-content-between align-items-center py-2">
          <img src={logo} className="logo" />

          <ul className="list-unstyled d-flex align-items-center m-0 gap-4 nav-menu">
            <li>
              <NavLink to={"/"}>Home</NavLink>
            </li>
            <li>
              <NavLink to={"products"}>Products</NavLink>
            </li>
            <li>
              <NavLink to={"about"}>About</NavLink>
            </li>
            <li>
              <NavLink to={"contact"}>Contact</NavLink>
            </li>
          </ul>
          {/* <div className="d-flex gap-2 align-items-center">
            <img src={mafaLogo} width={50} />
            <h1 className="small m-0">MAHFA RESOURCES</h1>
          </div> */}
          <div className="enquire">
            <Link to="enquire" className="btn-brand-primary">
              Enquire now
            </Link>
          </div>
          <button className="ham-btn" onClick={() => setShowMobileNav(true)}>
            <BiMenuAltRight />
          </button>
        </div>
      </div>
      {showMobileNav && (
        <div className="mobile-menu">
          <div className="mobile-nav-list">
            <img src={logo} width={150} />
            <ul className="list-unstyled">
              <li>
                <NavLink to={"/"} onClick={() => setShowMobileNav(false)}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"products"}
                  onClick={() => setShowMobileNav(false)}
                >
                  Products
                </NavLink>
              </li>
              <li>
                <NavLink to={"about"} onClick={() => setShowMobileNav(false)}>
                  About
                </NavLink>
              </li>
              <li>
                <NavLink to={"contact"} onClick={() => setShowMobileNav(false)}>
                  Contact
                </NavLink>
              </li>
            </ul>
            <div>
              <Link
                to="enquire"
                className="btn-brand-primary"
                onClick={() => setShowMobileNav(false)}
              >
                Enquire now
              </Link>
            </div>
            <button
              className="close-menu"
              onClick={() => setShowMobileNav(false)}
            >
              <FaTimes />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
