import logo from "./../assets/images/medzon-logo.png";
import { CiMail, CiMap } from "react-icons/ci";
import {
  BsFacebook,
  BsTwitter,
  BsInstagram,
  BsTelephone,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import mafaLogo from "./../assets/images/mafalogo.png";
import isoLogo from "./../assets/images/ISO.png";
import ceLogo from "./../assets/images/CE.png";
import astmLogo from "./../assets/images/ASTM.png";
import enLogo from "./../assets/images/en.png";

const Footer = () => {
  return (
    <div className="footer bg-light pt-5 pb-3">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            {/* <div>
              <img src={logo} width={200} />
            </div> */}
            <div className="d-flex gap-2 align-items-center mb-2">
              <img src={mafaLogo} width={50} />
              <div>
                <h5 className=" m-0">MAHFA RESOURCES (M) SDN. BHD.</h5>
                {/* <small>202301001530 (1495449 - D)</small> */}
              </div>
            </div>
            <div className="d-flex gap-2  align-items-center my-3">
              <img src={isoLogo} width={40} />
              <img src={ceLogo} width={40} />
              <img src={astmLogo} width={40} />
              <img src={enLogo} width={40} />
            </div>
          </div>
          <div className="col-md-2">
            <div className="mb-2">
              <h4>Products</h4>
              <ul className="list-unstyled m-0 p-0 d-flex flex-column gap-2">
                <li>
                  <Link to={"product/lima"}>Lima</Link>
                </li>
                <li>
                  <Link to={"product/nova"}>Nova</Link>
                </li>
                <li>
                  <Link to={"product/byon"}>Byon</Link>
                </li>
                <li>
                  <Link to={"product/sierra"}>Sierra</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mb-2">
              <h4>Contacts</h4>
              <ul className="list-unstyled d-flex flex-column gap-2">
                <li className="d-flex gap-2 align-items-center">
                  <span>
                    <BsTelephone />
                  </span>
                  <span>+60 178 566 700</span>
                </li>
                <li className="d-flex gap-2 align-items-center">
                  <span>
                    <CiMail />
                  </span>
                  <span>info@mahfaresources.com</span>
                </li>
                <li className="d-flex gap-2 ">
                  <span>
                    <CiMap />
                  </span>
                  <span>
                    23F, Blok 2, Tingkat 6, Pusat Perniagaan Worldwide Jalan
                    Tinju 13/50, Seksyen 13, 40100 Shah Alam, Selangor Darul
                    Ehsan, Malaysia.
                  </span>
                </li>
                <li>
                  <div className="d-flex gap-3 my-2 social-link">
                    <Link to={"/"}>
                      <BsFacebook />
                    </Link>
                    <Link to={"/"}>
                      <BsTwitter />
                    </Link>
                    <Link to={"/"}>
                      <BsInstagram />
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <p>© 2023 MAHFA RESOURCES (M) SDN. BHD.</p>
          <p>
            Designed and Developed By{" "}
            <a href="https://sazpress.com">Sazpress</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
