import { Link } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
import { useState } from "react";
import { CiMail, CiMap } from "react-icons/ci";
import { BsFacebook, BsTwitter, BsInstagram } from "react-icons/bs";
import axios from "axios";
import { toast } from "react-hot-toast";
import api from "../api/api";
const ContactUs = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState("Send Message");
  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    const data = {
      name: name,
      phone: phone,
      email: email,
      message: message,
    };

    if (name && phone && email && message) {
      try {
        setIsSuccess("Sending...");
        api
          .post(
            "https://llfzjoif59.execute-api.ap-southeast-2.amazonaws.com/contact",
            data
          )
          .then((res) => {
            if (res.status === 200) {
              toast.success("Message sent successfully!");
              setIsSuccess("Send Message");
              setName("");
              setPhone("");
              setEmail("");
              setMessage("");
              setIsSubmit(false);
            }
          });
      } catch (err) {
        console.log(err);
        setIsSubmit(false);
        setIsSuccess("Submit");
      }
    }
  };
  return (
    <div className="contact-us">
      <div className="heading-txt py-3">
        <div className="container">
          <h2>Contact Us</h2>
        </div>
      </div>
      <div className="container">
        <div className="row pb-5">
          <div className="col-md-6 bg-light p-4 ">
            <div>
              <p>
                Our dedicated customer support team is here to assist you.
                Whether you have questions about our glove collections, need
                guidance on choosing the right gloves for your needs, or require
                information about customization options, we're ready to help.
              </p>
              <ul className="list-unstyled d-flex flex-column gap-2">
                <li className="d-flex gap-2 align-items-center">
                  <span>
                    <FaPhoneAlt />
                  </span>
                  <span>+60 178 566 700</span>
                </li>
                <li className="d-flex gap-2 align-items-center">
                  <span>
                    <CiMail />
                  </span>
                  <span>info@mahfaresources.com</span>
                </li>
                <li className="d-flex gap-2 ">
                  <span>
                    <CiMap />
                  </span>
                  <span>
                    23F, Blok 2, Tingkat 6, Pusat Perniagaan Worldwide Jalan
                    Tinju 13/50, Seksyen 13, 40100 Shah Alam, Selangor Darul
                    Ehsan, Malaysia.
                  </span>
                </li>
                <li>
                  <div className="d-flex gap-3 my-2 social-link">
                    <Link to={"/"}>
                      <BsFacebook />
                    </Link>
                    <Link to={"/"}>
                      <BsTwitter />
                    </Link>
                    <Link to={"/"}>
                      <BsInstagram />
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <form>
                <div className="mb-2">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter Name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                  {isSubmit && !name && (
                    <small className="text-secondary">Enter name</small>
                  )}
                </div>
                <div className="mb-2">
                  <label>Phone</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter Phone Number"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                  {isSubmit && !phone && (
                    <small className="text-secondary">Enter phone number</small>
                  )}
                </div>
                <div className="mb-2">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  {isSubmit && !email && (
                    <small className="text-secondary">Enter email</small>
                  )}
                </div>
                <div className="mb-2">
                  <label>Message</label>
                  <textarea
                    className="form-input"
                    placeholder="Enter Message"
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                  ></textarea>
                  {isSubmit && !message && (
                    <small className="text-secondary">Enter message</small>
                  )}
                </div>
                <div className="d-flex">
                  <button
                    className="btn-brand-primary w-100"
                    onClick={handleSubmit}
                  >
                    {isSuccess}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
