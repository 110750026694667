import lima from "./../assets/images/lima-product.png";
import limaLogo from "./../assets/images/lima-logo.png";

const Lima = () => {
  return (
    <div className="lima py-3">
      <div className="container">
        <div className="row align-items-center pb-4">
          <div className="col-md-6 d-flex justify-content-center">
            <img src={lima} className="lima-img" />
          </div>
          <div className="col-md-6">
            <div>
              <div className="mb-3 pb-2 border-bottom">
                <img src={limaLogo} width={200} />
              </div>
              <h3>Latex Examination Gloves Powdered | Powder free</h3>
              <p>
                Medzon Lima, our non-sterile powdered and powder free gloves are
                made with high quality manufacturing & natural rubber latex to
                deliver comfort & barrier protection
              </p>
              <div>
                <h4>Key Features and Benefits</h4>
                <p>
                  Natural rubber latex for strong, reliable gloves. Lightly
                  powdered for ease of donning. Custom design enhances comfort
                  and fit. Textured finish for secure grip
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="my-4 ">
          <h4>Product Description</h4>
          <table className="table table-sm table-bordered">
            <tbody>
              <tr>
                <td className="bg-light">Type</td>
                <td>
                  Latex Examination Glove, Powdered & Powder free, Non-sterile
                </td>
              </tr>
              <tr>
                <td className="bg-light">Material</td>
                <td>Natural High Grade Rubber Latex</td>
              </tr>
              <tr>
                <td className="bg-light">Colour</td>
                <td>Natural</td>
              </tr>
              <tr>
                <td className="bg-light">Shelf Life</td>
                <td>
                  5 years from the date of manufacturing with the below storage
                  condition
                </td>
              </tr>
              <tr>
                <td className="bg-light">Storage Condition</td>
                <td>
                  The gloves shall maintain their properties when stored in a
                  dry condition. Avoid direct sunlight.
                </td>
              </tr>
              <tr>
                <td className="bg-light">Packing Format</td>
                <td>100 pcs gloves x 10 dispensers x 1 carton</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row ">
          <div className="col-md-6">
            <div>
              <h4>Performance Specifications</h4>
              <table className="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th className="bg-light">Description</th>
                    <th className="bg-light">Size</th>
                    <th className="bg-light">Standard</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Length(mm)</td>
                    <td>All Sizes</td>
                    <td>min 240</td>
                  </tr>
                  <tr>
                    <td>Palm Width(mm)</td>
                    <td>
                      <ul className="list-unstyled d-flex flex-column">
                        <li>XS</li>
                        <li>S</li>
                        <li>M</li>
                        <li>L</li>
                        <li>XL</li>
                      </ul>
                    </td>
                    <td>
                      {" "}
                      <ul className="list-unstyled d-flex flex-column">
                        <li>76 +/-3</li>
                        <li>84 +/-3</li>
                        <li>94 +/-3</li>
                        <li>105 +/-3</li>
                        <li>113 +/-3</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Thickness (mm) *single wall</td>
                    <td>All Sizes</td>
                    <td>
                      {" "}
                      <ul className="list-unstyled d-flex flex-column">
                        <li>
                          Finger: 0.10 +/- 0.02 (Typical value: 0.08 - 0.11)
                        </li>
                        <li>
                          Palm: 0.10 +/- 0.02 (Typical value: 0.08 - 0.10)
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h4>Physical Properties</h4>
              <table className="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th className="bg-light">Description</th>
                    <th colSpan={2} className="bg-light">
                      Standard
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>Before Aging</th>
                    <th>After Aging</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Elongation at Break (%)</td>
                    <td>Min 650 (Typical value: 650 - 750)</td>
                    <td>Min 500(Typical value:500 - 700)</td>
                  </tr>
                  <tr>
                    <td>Tensile Strength (MPa)</td>
                    <td>Min 18(Typical value: 18 - 22)</td>
                    <td>Min 14(Typical value: 14 - 18)</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lima;
