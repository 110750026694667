import sierra from "./../assets/images/sierra-product.png";
import sierraLogo from "./../assets/images/sierra-logo.png";

const Sierra = () => {
  return (
    <div className="sierra py-3">
      <div className="container">
        <div className="row align-items-center pb-4">
          <div className="col-md-6 d-flex justify-content-center">
            <img src={sierra} className="sierra-img" />
          </div>
          <div className="col-md-6">
            <div>
              <div className="mb-3 pb-2 border-bottom">
                <img src={sierraLogo} width={200} />
              </div>
              <h3>Latex Sterile Surgical Gloves Powdered | Powder free</h3>
              <p>
                Medzon Sierra, our sterile surgical powder free gloves is
                designed to deliver comfort & barrier protection with a thin
                film formulation for unrestricted tactile sensitivity. Polymer
                coated powder free interior enables incredible smooth donning
                experience.
              </p>
              <div>
                <h4>Key Features and Benefits</h4>
                <p>
                  Excellent tactile sensitivity ideal for palpating veins &
                  starting IVs. Textured surface for secure and sure grip.
                  Protection from latex allergies in healthcare workers and
                  patients. Recommended for brief, low-risk health procedures.
                  Environmentally friendly facilities
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="my-4">
          <h4>Product Description</h4>
          <table className="table table-sm table-bordered">
            <tbody>
              <tr>
                <td className="bg-light">Type</td>
                <td>Nitrile Examination Glove, Powder free, Non-sterile</td>
              </tr>
              <tr>
                <td className="bg-light">Material</td>
                <td>Natural High Grade Rubber Latex</td>
              </tr>
              <tr>
                <td className="bg-light">Design</td>
                <td>Ambidextrous, beaded cuff, Finger Textured, Chlorinated</td>
              </tr>
              <tr>
                <td className="bg-light">Shelf Life</td>
                <td>5 years from the date of manufacturing</td>
              </tr>
              <tr>
                <td className="bg-light">Packing Format</td>
                <td>50 Pair Pouch x 10 dispensers x 1 carton</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row ">
          <div className="col-md-6">
            <div>
              <h4>Performance Specifications</h4>
              <table className="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th className="bg-light">Description</th>
                    <th className="bg-light">Size</th>
                    <th className="bg-light">Standard</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Length(mm)</td>
                    <td>All Sizes</td>
                    <td>min 240</td>
                  </tr>
                  <tr>
                    <td>Palm Width(mm)</td>
                    <td>
                      <ul className="list-unstyled d-flex flex-column">
                        <li>XS</li>
                        <li>S</li>
                        <li>M</li>
                        <li>L</li>
                        <li>XL</li>
                      </ul>
                    </td>
                    <td>
                      {" "}
                      <ul className="list-unstyled d-flex flex-column">
                        <li>70 +/-6</li>
                        <li>83 +/-6</li>
                        <li>95 +/-6</li>
                        <li>108 +/-6</li>
                        <li>114 +/-6</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Thickness (mm) *single wall</td>
                    <td>All Sizes</td>
                    <td>
                      {" "}
                      <ul className="list-unstyled d-flex flex-column">
                        <li>Finger: Min 0.16</li>
                        <li>Palm: min 0.14</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h4>Physical Properties</h4>
              <table className="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th className="bg-light">Description</th>
                    <th colSpan={2} className="bg-light">
                      Standard
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>Before Aging</th>
                    <th>After Aging</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Elongation at Break (%)</td>
                    <td>Min 750%</td>
                    <td>Min 560%</td>
                  </tr>
                  <tr>
                    <td>Tensile Strength (MPa)</td>
                    <td>Min 24</td>
                    <td>Min 18</td>
                  </tr>
                  <tr>
                    <td>Force at break (N)</td>
                    <td>Min 9N</td>
                    <td>Min 9N</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sierra;
