import about from "./../assets/images/factory-workers-white-lab-suits-face-masks-producing-tv-sets-green-assembly-line.jpg";

const AboutUs = () => {
  return (
    <div className="about">
      <div className="heading-txt py-3">
        <div className="container">
          <h2>About Us</h2>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div>
              <p>
              Welcome to MAHFA RESOURCES (M) SDN. BHD., where a passion for crafting exceptional gloves meets a legacy of 7 years in the industry. We stand as a testament to innovation, quality, and a dedication to redefining hand protection through our well-known brand Medzon.
              </p>
            </div>
            <div>
              <h3>Our Journey</h3>
              <p>
              Our journey began with a vision – to create gloves that don't just shield hands, but elevate the experiences of those who wear them. Over the years, this vision has fuelled our growth, propelling us to the forefront of gloves manufacturing and sales.
              </p>
            </div>
            <div>
              <h3>Melding Tradition and Technology</h3>
              <p>
              In an era where technology often overshadows tradition, we embrace both. Our manufacturing process marries time-honoured craftsmanship with cutting-edge techniques, resulting in gloves that encapsulate the essence of artistry and functionality.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <img src={about} className="about-img mb-2" />
            </div>
          </div>
        </div>
        <div>
          <div>
            <h3>Elevating Quality Standards</h3>
            <p>
            Quality is more than a standard; it's a commitment. At Medzon, quality permeates every stitch, every material choice, and every aspect of our gloves. Our rigorous quality checks ensure that only the best reaches your hands.
            </p>
          </div>
          <div>
            <h3>Masters of Many Industries</h3>
            <p>
            We've journeyed alongside various industries, understanding their unique demands. From hardwearing gloves that brave industrial environments to precision-crafted medical gloves, our expertise spans sectors, making us a trusted partner across the board.
            </p>
          </div>
          <div>
            <h3>Sustainability at Heart</h3>
            <p>
            We bear a responsibility to the environment and future generations. Our sustainability initiatives infuse our manufacturing process, striving for greener practices, and eco-conscious materials that echo our commitment to a better world.
            </p>
          </div>
          <div>
            <h3>Crafting Partnerships</h3>
            <p>
            More than a transaction, your choice to wear our gloves forges a partnership. With Medzon, you're not just acquiring hand protection; you're entering a collaboration aimed at enhancing safety, performance, and confidence.
            </p>
          </div>
          <div className="bg-light p-3 my-3 rounded">
            <h3>Join the Medzon Journey</h3>
            <p>
            Our legacy is one of continuous innovation, driven by our dedication to your protection and satisfaction. Explore our comprehensive collection, from work gloves that withstand challenges to fashion-forward accessories that make a statement.
            </p>
            <p>
            Thank you for considering Medzon as your hand protection companion. We invite you to embark on the Medzon journey, where gloves aren't just products – they're an embodiment of our shared commitment to excellence.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
