import { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Enquiry from "./Enquiry";
import { Toaster } from "react-hot-toast";

const MainLayout = () => {
  const [showEnquiry, setShowEnquiry] = useState(false);

  return (
    <div>
      <Header />
      <div className="main">
        <Toaster />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
