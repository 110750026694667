import { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
const Testimonial = () => {
  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const handlePrevClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNextClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <>
      <div className="row justify-content-between py-5">
        <div className="col-md-4">
          <div>
            <h3>Hear What Our Customers Are Saying</h3>
            <p>
              Discover the Stories of Satisfied Clients Who Trust Our Gloves for
              Quality and Performance
            </p>
            <div className="d-flex gap-2 btn-nav mb-2">
              <button onClick={handlePrevClick}>
                <span>
                  <GrFormPrevious />
                </span>
              </button>
              <button onClick={handleNextClick}>
                <span>
                  <GrFormNext />
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6 bg-light p-3">
          <Slider ref={sliderRef} {...settings}>
            <div>
              <p>
                "I've been using gloves from medzon for years in my heavy-duty
                construction work. These gloves offer incredible durability and
                protection without compromising on comfort. I wouldn't trust any
                other brand for my hand safety."
              </p>
              <p className="text-secondary">John M., Construction Supervisor</p>
            </div>
            <div>
              <p>
                "As a healthcare professional, I can't compromise on the quality
                of my medical gloves. The gloves from medzon have consistently
                met and exceeded industry standards. Their attention to detail
                and commitment to hygiene make them my go-to choice."
              </p>
              <p className="text-secondary">
                Dr. Emily P., Medical Practitioner
              </p>
            </div>
            <div>
              <p>
                "When it comes to sports gloves, performance matters. I've tried
                various brands, but Medzon stands out for its superior grip and
                flexibility. Whether I'm cycling or weightlifting, their gloves
                give me the confidence to push my limits."
              </p>
              <p className="text-secondary">Alex R., Fitness Enthusiast</p>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
